import { Link } from "react-router-dom"
import{passport} from '../../assets'
import Image from "components/Image/Image"
import './style.css'
const DetailBlog = () => {
    return(
        <div className="blog--page">
            <section className="page-header">
                <h1> Controlled Substance Convictions & Immigration Laws</h1>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                        <li className="breadcrumb-item"><Link to={'/'}>News</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Controlled Substance Convictions & Immigration Laws</li>
                    </ul>
                </nav>
            </section>  
            <div className="container" >
                <figure className="img-news" style={{margin:'30px 0 15px'}}>
                    <Image ImgSrc={passport} />
                </figure>
                <span className="date">Dec 03rd, 2022</span>
                <p>
                The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary. The languages only differ in their grammar, their pronunciation and their most common words.

Everyone realizes why a new common language would be desirable: one could refuse to pay expensive translators. To achieve this, it would be necessary to have uniform grammar, pronunciation and more common words.
                </p>
                
                <p>
                One could refuse to pay expensive translators. To achieve this, it would be necessary to have uniform grammar, pronunciation and more common words.
                </p>
                <p>
                pIf several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages. The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental.

A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame.
                </p>
                

            </div>   
        </div>
    )
}

export default DetailBlog