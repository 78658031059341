import { lazy,Suspense } from 'react';
import './style.css';
import Loading from '../../components/Loading/loading';

const Banner = lazy(() => import("./components/section1"));
const Services = lazy(() => import("./components/section2"))
const Blog = lazy(() => import("./components/section3") )
const Team = lazy(() => import("./components/section4"))
const Contact = lazy(() => import("./components/section5"))


const HomePage = () => {
  return (
    <div className="main">
      <Suspense fallback={<Loading />}>
        <Banner />
        <Services />
        <Blog />
        <Team />
        <Contact />
      </Suspense>
    </div>
  );
};

export default HomePage;
