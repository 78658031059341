import React, { useEffect, useState, } from "react";
import { Link } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import{
  Logo
} from '../../../../assets'


const Header = () => {

  const [scroll,setScroll] = useState(0);
  const handleScroll = () => setScroll(document.documentElement.scrollTop) 
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)

  }, [])
  const classname = scroll > 50 ? "scrolled" : "";
  /**/ 
  

  return (
    <header className={classname}>
      <div className="container">
        <Link to={'/'} className="logo" title="logo Egamat">
          <img src={Logo} alt="logo Egamat" />
        </Link>
        <nav>
          <ul className="menu">
            <li>
              <AnchorLink href='#Services'>Services </AnchorLink>
            </li>
            <li>
              <AnchorLink href='#Blogs'>Blogs </AnchorLink>
            </li>
            <li>
              <AnchorLink href='#Team'>Our Team  </AnchorLink>
            </li>
            <li>
              <AnchorLink href='#Contact'>Contact  </AnchorLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
