import React from "react";
import { Link } from "react-router-dom";
import {logoFooter} from '../../../../assets'


const Footer = () => {
    return(
        <footer>
            <div className="container">
                <figure className="logo--footer" >
                    <img src={logoFooter} alt="logo FIDAO" />
                </figure>     
                <ul className="menu--footer">
                    <li><span className="title--menu">Study Abroad</span></li>
                    <li>
                        <Link to={"/"}> Guide for parents</Link>
                    </li>
                    <li>
                        <Link to={"/"}> GC Events</Link>
                    </li>
                    <li>
                        <Link to={"/"}>Institution answers </Link>
                    </li>
                    <li>
                        <Link to={"/"}>Student Essentials </Link>
                    </li>
                </ul>
                <ul className="menu--footer">
                    <li><span className="title--menu">Useful Links</span></li>
                    <li>
                        <Link to={"/"}>Study abroad intakes</Link>
                    </li>
                    <li>
                        <Link to={"/"}>Guide for parents</Link>
                    </li>
                    <li>
                        <Link to={"/"}>GC Events</Link>
                    </li>
                    <li>
                        <Link to={"/"}>Institution answers</Link>
                    </li>
                </ul>
                <ul className="menu--footer">
                    <li><span className="title--menu">IELTS </span></li>
                    <li>
                        <Link to={"/"}>What is IELTS?</Link>
                    </li>
                    <li>
                        <Link to={"/"}>Prepare for IELTS</Link>
                    </li>
                    <li>
                        <Link to={"/"}> IELTS for UKVI</Link>
                    </li>
                    <li>
                        <Link to={"/"}>About GC IELTS </Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;