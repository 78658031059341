import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./screen/home";
import DetailBlog from "screen/detailBlog";
import Layout from "./components/Layout/Layout";
import "./css/style.css";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/blog" element={<DetailBlog />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
    
  );
}

export default App;
