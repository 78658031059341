import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from '../../configs'

const Layout = ({children}) => {
    return(
        <div>
            <ScrollToTop />
            <Header />
            {children}
            <Footer />
        </div>
    )
}
export default Layout